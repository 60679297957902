import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import Button from "../components/PageComponents/Button";
import { WhatWeDoCards } from "../components/PageSections/WhatWeDo";
import FullVideo from "../components/PageSections/FullVideo";
import {
  StoriesGrid,
  StoryCards
} from "../components/PageSections/StoriesGrid";
import OurPartners from "../components/PageSections/OurPartners";
import Newsletter from "../components/PageSections/Newsletter";

const DonatePage = () => {
  const [isDropdownOpen, setDropdown] = useState(false);

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "get-them-out" } }) {
        nodes {
          title
          featured_media {
            source_url
          }
          acf {
            hero {
              header
              subheader
            }
            what_we_do {
              header
              copy
            }
            video {
              title
              video
              poster {
                source_url
              }
            }
            donate_today {
              header
              subheader
            }
            successes {
              header
            }
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
      wordpressPage(slug: { eq: "home" }) {
        acf {
          what_we_do {
            title
            actions {
              copy
              title
              image {
                source_url
              }
            }
          }
          our_partners {
            client_name
            url
            logo {
              source_url
            }
          }
        }
      }
      allWordpressPost(
        filter: { categories: { elemMatch: { name: { eq: "Story" } } } }
        limit: 3
      ) {
        nodes {
          id
          acf {
            age
            image {
              source_url
            }
            snippet
          }
          title
          slug
        }
      }
    }
  `);

  const {
    hero,
    what_we_do,
    video,
    donate_today,
    successes
  } = data.allWordpressPage.nodes[0]?.acf;
  const featured_image =
    data.allWordpressPage.nodes[0].featured_media?.source_url;
  const what_we_do_dropdown = data.wordpressPage.acf.what_we_do;
  const partners = data.wordpressPage.acf.our_partners;
  const { yoast_meta, title } = data.allWordpressPage.nodes[0];

  const stories = data.allWordpressPost.nodes;

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={{ source_url: seo_fb_image }}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={{ source_url: seo_tw_image }}
        metaDescTwitter={seo_tw_desc}
      />
      <div
        className="flex items-center md:min-h-screen pb-12 pt-32 bg-primary bg-cover bg-left"
        style={{ backgroundImage: `url(${featured_image})` }}
      >
        <div className="container text-center text-white">
          <h1 className="mx-auto text-xl sm:max-w-lg sm:text-2xl lg:max-w-3xl lg:text-4xl leading-snug tracking-normal">
            {hero?.header}
          </h1>
          <h2 className="mx-auto max-w-xs text-6xl md:max-w-full md:text-7xl lg:text-9xl xl:text-10xl">
            {hero?.subheader}
          </h2>
          <div className="mt-8 lg:mt-12">
            <a href="https://eachstephome.donorsupport.co/-/XYWQRRQB">
              <Button style="light">Donate Now</Button>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-white py-16 lg:py-24">
        <div className="container">
          <h3 className="text-center text-sm mb-2 xl:text-base">
            {what_we_do?.header}
          </h3>
          <p className="text-center leading-tight font-bold text-xl mx-auto max-w-md md:text-2xl md:max-w-xl lg:text-4xl lg:max-w-3xl">
            {what_we_do?.copy}
          </p>
          <div
            className={`flex justify-center md:mt-8 lg:mt-10 ${isDropdownOpen &&
              "hidden"}`}
          >
            <Button
              style="minimal"
              color="magenta"
              onClick={() => setDropdown(true)}
            >
              Learn more about what we do
            </Button>
          </div>

          <div
            className={`transition-opacity duration-500 ${
              isDropdownOpen
                ? "opacity-1 visible h-auto mt-20"
                : "opacity-0 invisible h-0"
            }`}
          >
            <WhatWeDoCards actions={what_we_do_dropdown?.actions} />
          </div>
        </div>
      </div>

      <FullVideo
        title={video?.title}
        video={video?.video}
        poster={video?.poster?.source_url}
      />

      <div className="bg-magenta text-center text-white py-16 lg:py-24">
        <div className="container">
          <h3 className="text-4xl sm:text-6xl md:text-7xl lg:text-9xl xl:text-10xl">
            {donate_today?.header}
          </h3>
          <p className="leading-tight font-bold text-xl mx-auto max-w-md md:text-2xl md:max-w-xl lg:text-4xl lg:max-w-3xl">
            {donate_today?.subheader}
          </p>
          <div className="flex justify-center mt-8">
            <a href="https://eachstephome.donorsupport.co/-/XYWQRRQB">
              <Button style="light">Donate Now</Button>
            </a>
          </div>
        </div>
      </div>

      <div className="py-16 lg:py-32">
        <div className="container">
          <h3 className="text-center leading-none text-4xl sm:text-5xl lg:text-6xl xl:text-7xl">
            {successes?.header}
          </h3>
          <div className="mt-8 md:mt-16 lg:mt-24">
            <StoriesGrid noPadding>
              <StoryCards stories={stories} />
            </StoriesGrid>
          </div>
          <div className="flex justify-center mt-8">
            <Link to="/successes/">
              <Button style="dark">See Our Successes</Button>
            </Link>
          </div>
        </div>
      </div>

      <OurPartners partners={partners} />

      <Newsletter />
    </Layout>
  );
};

export default DonatePage;
